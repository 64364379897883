export const apiPaths = {
  // DASHBOARD
  dashboardDoctorApi: '/dashboard_doctor',
  dashboardAdminApi: '/dashboard_admin',

  // APPOINTMENT
  appointmentsApi: '/appointments',
  appointmentStatusApi: '/appointment_statuses',

  // PATIENT
  patientsApi: '/patients',
  prefixesApi: '/prefixes',
  bloodGroupsApi: '/blood_groups',
  gendersApi: '/genders',
  maritalStatusesApi: '/marital_statuses',
  racesApi: '/races',
  careersApi: '/careers',
  educationsApi: '/educations',
  smokingConditionsApi: '/smoking_conditions',
  alcoholConditionsApi: '/alcohol_conditions',
  allergicConditionsApi: '/allergic_conditions',
  medicalTreatmentRightsApi: '/medical_treatment_rights',
  hospitalApi: '/hospitals',
  tambonsApi: '/tambons',
  amphursApi: '/amphurs',
  provincesApi: '/provinces',

  // OPD
  opdCardApi: '/opd_cards',
  opdExaminationFileApi: '/examination_files',
  opdAllExaminationFileApi: '/examination_files/create_all',
  opdCardRequestsApi: '/opd_card_requests',
  opdCardStatusMasterApi: '/opd_card_status_masters',
  medicalCertificatesApi: '/medical_certificates',
  medicalCertificateTypesApi: '/medical_certificate_types',
  medicalReceiptsApi: '/receipts',
  paymentTypesApi: '/payment_types',

  // REPORT
  revenueSummaryReport: '/reports/revenue_summary',
  caseSummaryReport: '/reports/case_summary',

  // GENERAL INFORMATION
  clinicsApi: '/clinics',
  banksApi: '/banks',
  bankAccountUsagesApi: '/bank_account_usages',
  clinicBankAccountsApi: '/clinic_bank_accounts',
  rolesApi: '/roles',
  staffPositionsApi: '/staff_positions',
  usersApi: '/users',
  roomsApi: '/rooms',

  // PRODUCT / MEDICINE / EQUIPMENT / STOCKS / COURSE
  skuMastersApi: '/sku_masters',
  unitsMasterApi: '/units',
  goodsUnitsApi: '/goods_units',
  goodsMastersApi: '/goods_masters',
  goodsCategoriesApi: '/goods_categories',
  goodsGroupsApi: '/goods_groups',
  goodsTypesApi: '/goods_types',
  goodsSetsApi: '/goods_sets',
  actingTypesApi: '/acting_types',
  stockImportApi: '/stock_imports',
  stockExportApi: '/stock_exports',
  stockExportReasonApi: '/stock_export_reasons',
  coursesApi: '/courses',
  patientCoursesApi: '/patient_courses',
  patientCoursesPayApi: '/receipts',
  staffsApi: '/staffs',

  // OPERATIVE
  operativeGroupsApi: '/operative_groups',
  operativesApi: '/operatives',
  doctorFeesApi: '/doctor_fees',
};
