import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true }],
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  subscription: Subscription;

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    if (value) {
      this.isError = true;
    }
  }

  @Input()
  set valid(value: any) {
    this.isValid = false;
    if (value) {
      this.isValid = true;
    }
  }

  @Input() set isCurrency(value: boolean) {
    if (value === true) {
      this.mask = 'separator.2';
      this.thousandSeparator = ',';
      this.dropSpecialCharacters = true;
      this.isFormatCurrency = true;
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() type = 'text';
  @Input() autocomplete = 'off';
  @Input() label: string;
  @Input() addonLabel: string = '';
  @Input() addonLabelClass: string;
  @Input() labelValue: string = '';
  @Input() placeholder = '';
  @Input() mask: string;
  @Input() dropSpecialCharacters: boolean = false;
  @Input() thousandSeparator: string;
  @Input() minlength: number = 0;
  @Input() maxlength: number = 1000;
  @Input() maxValue: number;
  @Input() minValue: number;
  @Input() tabindex: number = 0;
  @Input() suffix: string;
  @Input() suffixClass: string;
  @Input() inputClass: string;
  @Input() classes: string;
  @Input() errorMessage: string = 'VALIDATOR.REQUIRED';
  @Input() showErrorMessage: boolean = true;
  @Input() focus: boolean = true;
  @Input() customPatterns: any;
  @Input() isRequire: boolean;

  @Input() debouceTime: number = 0;

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  @Output() valueChange = new EventEmitter();
  @Output() inputChange = new EventEmitter();

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();

  isError = false;
  isValid = false;
  isFormatCurrency = false;

  showPwd = false;

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges
      .pipe(debounceTime(this.debouceTime), distinctUntilChanged())
      .subscribe((val) => {
        let value = this.form.value;
        if (typeof this.form.value === 'string') {
          value = this.form.value.trim();
        }
        this.valueChange.emit(value);
        this.onChange(value);
      });
  }

  handleFocusOut() {
    let value = this.form.value;
    if (this.isFormatCurrency === true) {
      value = parseFloat(value).toFixed(2);
    }
    if (typeof value === 'string') {
      value = value.trim();
    }
    if (this.focus) {
      this.form.setValue(value, { emitEvent: false });
      this.onChange(value);
    }
  }

  onInputChange(value: any) {
    const inputElement = value.target as HTMLInputElement;
    let inputValue: string | number = inputElement.value;
    if (!Number.isNaN(+inputValue)) {
      if (this.maxValue !== undefined && +inputValue > this.maxValue) {
        value = this.setMaximum(inputValue);

        this.form.setValue(value, { emitEvent: false });
        this.inputChange.emit(value);
        // this.form.setValue(this.maxValue, { emitEvent: false });
      } else if (this.minValue !== undefined && +inputValue < this.maxValue) {
        // this.form.setValue(null, { emitEvent: false });
      } else {
        this.inputChange.emit(inputValue);
      }
    } else {
      this.form.setValue(inputValue);
      this.inputChange.emit(inputValue);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    // Use patchValue fot unmatch key
    if (this.isFormatCurrency) {
      obj = parseFloat(obj).toFixed(2);
    }
    this.form.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  setMaximum(value: any) {
    if (this.isNotNull(value) && this.isMaximum(value)) {
      return this.maxValue;
    } else {
      return value;
    }
  }

  setMinimum(value: any) {
    if (this.isNotNull(value) && this.isMinimum(value)) {
      return this.minValue;
    } else {
      return value;
    }
  }

  isMaximum(value: number) {
    return Number(value) > Number(this.maxValue);
  }

  isMinimum(value: number) {
    return this.minValue && Number(value) < Number(this.minValue);
  }

  isNotNull(value) {
    return value !== null;
  }
}
